<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Inschrijving</span>
				<span class="pl-2" v-else>Formulario di inskripshon OSBOD 2025-2026</span>
		
			</v-toolbar-title>
		</v-toolbar>
		<v-stepper v-model="e1">
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" step="1">
					<p class="font-weight-black title">Estudio</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 2" step="2">
					<p class="font-weight-black title">Informashon di studiante</p>
				</v-stepper-step>
				<!-- <v-divider></v-divider>
				<v-stepper-step :complete="e1 > 3" step="3">
					<p class="font-weight-black title">Vooropleiding(en):</p>
				</v-stepper-step> -->
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 3" step="3">
					<p class="font-weight-black title">Informashon di kontakto</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 4" step="4">
					<p class="font-weight-black title">Registrá</p>
				</v-stepper-step>
			</v-stepper-header>
			<v-form lazy-validation>
				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card>
							<v-col cols="12" sm="6">
								
								<p class="font-weight-bold">
								<v-radio-group v-model="form.old_student" label="Skohe for di e siguiente 2 opshonnan:*" required :rules="[rules.required]"
										:error-messages="errorMessages" >
								<v-radio
									label="Mi ta un èks studiante di OSBOD "
									value="Oude student"
								></v-radio>
								<v-radio
									label="Mi ta inskribiendo pa promé biaha na OSBOD"
									value="Nieuwe student"
								></v-radio>
								</v-radio-group>
								</p>
								<p class="font-weight-bold">Mi ta deseá pa inskribí pa e estudio:</p>
									<v-select dense :items="sectors" item-text="name_pap" item-value="id" 
										v-model="form.sector_id" filled label="Sektor*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>

									<v-select dense :items="levels" item-text="tbl_sys_level.name_pap" item-value="tbl_sys_level.id" 
										v-model="form.level_id" filled label="Nivel*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>
									
									<v-select dense :items="studys" item-text="name" item-value="id" 
										v-model="form.study_id" filled label="Estudio*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>

									<v-text-field :items="selectedstudycost" item-text="registration_cost" item-dense v-model="form.registration_cost"
										item-value="registration_cost" label="Gastu di inskripshon" filled readonly>
									</v-text-field>
									<v-text-field :items="selectedstudycost" dense v-model="form.study_cost" item-text="study_cost" item-value="study_cost"  label="Gastunan di estudio" filled
										readonly>
									</v-text-field>
									<!-- <v-text-field dense v-model="form.material_cost" label="Lesmateriaal kosten" filled
										readonly>
									</v-text-field> -->
							</v-col>
						</v-card>
						<v-btn color="primary" @click="submitFormTransitionStudie()" depressed>
							Siguiente
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Anulá</v-btn>
					</v-stepper-content>
					<v-stepper-content step="2">
						<v-card-text>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.maidenname" label="Fam kasá" filled>
									</v-text-field>
									<v-text-field dense v-model="form.surname" label="Fam*"  hint="Den kaso si ta kasa, yena nomber di soltera" filled
										:rules="[rules.required]" :error-messages="errorMessages" required
										>
									</v-text-field>
									<v-text-field dense v-model="form.firstname" label="Nòmber kompletu*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.middlename" label="Nòmber(nan) mei mei kompletu" filled
										>
									</v-text-field>
									<v-select dense :items="genders" item-text="name_pap" item-value="id"
										v-model="form.gender_id" filled label="Sekso*" :rules="[rules.required]"
										:error-messages="errorMessages" required></v-select>
									<v-menu ref="menu_birthdate" v-model="menu_birthdate"
										:close-on-content-click="false" :return-value.sync="form.birthdate"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.birthdate" label="Fecha di nasementu*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.birthdate" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_birthdate = false">Anulá</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_birthdate.save(form.birthdate)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="birthplaces" item-text="name_pap" item-value="id"
										v-model="form.birthplace_id" filled label="Lugá di nasementu*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="nationalitys" item-text="name_pap" item-value="id"
										v-model="form.nationality_id" filled label="Nashonalidat*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>


								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.government_id" label="Number di I.D.*" filled
										:counter=10 :rules="[rules.required, rules.governmentid, rules.number_only]"
										:error-messages="errorMessages" required>
									</v-text-field>
									<v-menu ref="menu_govid_exp_date" v-model="menu_govid_exp_date"
										:close-on-content-click="false" :return-value.sync="form.govid_exp_date"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.govid_exp_date" label="Fecha di vensementu di I.D.*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.govid_exp_date" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_govid_exp_date = false">Anulá</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_govid_exp_date.save(form.govid_exp_date)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="maritial_statuss" item-text="name_pap" item-value="id"
										v-model="form.maritial_status_id" filled label="Estado sivil*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="denominations" item-text="name_pap" item-value="id"
										v-model="form.denomination_id" filled label="Religion*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-text-field dense v-model="form.address" label="Adrès*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.neighboorhood" label="Bario*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.email" label="Adrès elektróniko*" filled
										:rules="[rules.required, rules.email]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-row>
										<v-col cols="12" sm="6">
											<v-text-field dense v-model="form.tel_mobile"
												prepend-inner-icon="mdi-cellphone" label="Number di telefòn selular*" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="6">
											<v-text-field dense v-model="form.tel_home"
												prepend-inner-icon="mdi-phone-classic" label="Number di telefòn fiho" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages">
											</v-text-field>
										</v-col>
										<!-- <v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_work"
												prepend-inner-icon="mdi-deskphone" label="Werk" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages"></v-text-field>
										</v-col> -->
									</v-row>



								</v-col>
							</v-row>
						</v-card-text>

						<v-btn color="secondary" @click="e1=e1-1">Regresá</v-btn>
						<v-btn color="primary" @click="submitFormTransition()" depressed>
							Siguiente
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Anulá</v-btn>

						
					</v-stepper-content>
					<v-stepper-content step="3">
						<!-- <v-card>
							<v-col cols="12" sm="6">
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_fo" label="FO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_level" label="t/m groep:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_year" label="in het jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vo" label="VO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_year" label="tot en met jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vsbo" label="VSBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_year" label="tot en met jaar:"
											filled>
										</v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_sbo" label="SBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_year" label="tot en met jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_other" label="Andere"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_year" label="tot en met jaar:"
											filled>
										</v-text-field>
									</v-col>
								</v-row>
							</v-col>
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Terug</v-btn>
						<v-btn color="primary" @click="e1=e1+1" depressed>
							Volgende
						</v-btn>
						<v-btn color="error" @click="$router.go(-1);">Annuleren</v-btn>
					</v-stepper-content>

					<v-stepper-content step="4"> -->
						<v-card>
							
							<v-col cols="12" sm="4">
								<p class="font-weight-bold">Informashon di kontakto den kaso di emergensia:</p>
								<v-text-field dense v-model="form.emergency_contact_1" label="Nòmber di kontakto  1*" filled
								:rules="[rules.required]" :error-messages="errorMessages" required>
								</v-text-field>
								<v-text-field dense v-model="form.emergency_phone_1" label="Number di kontakto 1*" prepend-inner-icon="mdi-cellphone" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
								</v-text-field>
								
							
								<v-text-field dense v-model="form.emergency_contact_2" label="Nòmber di kontakto  2" filled>
								</v-text-field>
								<v-text-field dense v-model="form.emergency_phone_2" label="Number di kontakto 2" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages">
								</v-text-field>
								
							
							</v-col>
							
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Regresá</v-btn>
						<v-btn color="primary" @click="submitFormTransitionEmergencyContact()" depressed>
							Siguiente
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Anulá</v-btn>
					</v-stepper-content>
					<v-stepper-content step="4">

						<v-card>
							<v-row>
								<v-col cols="12" sm="12">
									<p class="font-weight-black title">Kondishonnan di inskripshon i pago</p>
									<p class="font-weight-bold">Aña eskolar 2025-2026</p>
								
									<p class="font-weight-bold">1.	Inskripshon pa un estudio</p>
									<p class="font-weight-regular">Mediante bo firma riba e dokumento di inskripshon, bo ta deklará ku bo ta bai di akuerdo ku e kondishonnan di pago. Tene kuenta ku kada aña skolar bo mester re-inskribí i paga sèn di re-inskripshon.</p>
									<p class="font-weight-bold">2.	Forma di pago</p>
									<p class="font-weight-regular">Mester depositá sèn di inskripshon i buki riba number di kuenta bankario di OSBOD: MCB-32.74.37.03 ku e siguiente informashon komo deskripshon:  nòmber di studiante, estudio i klas. Sèn di skol mester keda depositá riba number di kuenta bankario di OSBOD MCB – 32.06.19.10, ku e siguiente informashon komo deskripshon: nòmber di studiante, estudio i klas. No ta aseptá sèn efektivo. Mester manda tur komprobante di pago via meil pa e trahadó atministrativo di bo sektor. Semper warda bo komprobante di pago original bon. Mester kanselá e montante kompletu promé ku final di aña skolar. Den kaso ku esaki no tuma lugá, lamentablemente lo bo NO risibí bo lista di sifra i/òf diploma.
									</p>
									<p class="font-weight-bold">3.	Kanselashon</p>
									<p class="font-weight-regular">Despues di 2 luna di ousensia na aktividatnan regular di skol sin a duna un motibu bálido, outomátikamente lo bo no forma parti mas di e estudio. Konsekuensia di esaki ta ku lo bo mester inskribí di nobo i paga sèn di inskripshon.</p>
									<p class="font-weight-bold">No por reklamá niun pago hasí.
									</p>
									<p class="font-weight-regular">Si pa un òf otro motibu bo ta deseá pa stòp ku e estudio, bo mester informá e trahadó atministrativo di bo sektor via di un meil.
									</p>
									<p class="font-weight-bold">4.	Material di lès</p>
									<p class="font-weight-regular">E lista di materialnan nesesario pa lès lo bo risibí na inisio di e estudio si bo a kumpli ku e obligashonnan finansiero.</p>
									<p class="font-weight-bold">5.	Partisipashon na èksamennan</p>
									<p class="font-weight-regular">Tur fecha importante inkluso fecha di evaluashon i rekuperashon bo ta haña riba bo planifikashon di aña. Tur regla di èksamen a keda konsultá i akordá ku Inspekshon di Enseñansa, por haña esaki den Buki di èksamen di OSBOD.</p>
									<p class="font-weight-bold">6.	Kambio durante estudio</p>
									<p class="font-weight-regular">OSBOD tin e derechi di hasi kambio den roster / dosente / lokalidat i orario na tur momentu. Na momentu ku  no tin sufisiente inskripshon, por kanselá un estudio. Studiantenan ku ya a inskribí lo keda informá por eskrito na tempu i tur obligashon finansiero lo keda anulá. Lo no tin niun gastu mará na esaki i nan lo risibí nan sèn di inskripshon bèk.</p>
									<v-checkbox v-model="form.registration_complete"
										label="Bo ta deklará ku bo a yena e informashon di inskripshon konforme realidat i kompletu.*"
										required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
									<v-checkbox v-model="form.registration_agree" required
										label="Bo ta deklará ku bo ta bai di akuerdo ku e kondishonnan di inskripshon i pago.*"
										:rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
								</v-col>
							</v-row>
						</v-card>
						<v-card-actions>
							<v-btn color="secondary" @click="e1=e1-1">Regresá</v-btn>
							<v-btn color="primary" @click="submitForm(id)" depressed>Registrá</v-btn>
							<v-btn color="error" @click="$router.go(-1);" depressed>Anulá</v-btn>
						</v-card-actions>
					</v-stepper-content>
				</v-stepper-items>
			</v-form>
		</v-stepper>



		<v-card-text>

			<v-row>


			</v-row>

		</v-card-text>
		<!-- <v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Opslaan</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Annuleren</v-btn>
		</v-card-actions> -->

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			U dient alle verplichte velden in te vullen! Druk op de terug knop om de verplichte velden alsnog in te
			vullen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "usersignupadd",
		props: ["id"],
		data() {
			return {
				cursector: 0,
				curlevel: 0,
				curstudy: 0,
				e1: 1,
				valid: true,
				errorMessages: '',
				formHasErrors: false,
				user: localStorage.getItem("user"),
				snackbar: false,
				schoolyears: [],
				studys: [],
				users: [],
				genders: [],
				birthplaces: [],
				nationalitys: [],
				maritial_statuss: [],
				denominations: [],
				sectors: [],
				levels: [],
				selectedstudycost: [],
				menu: false,
				menu_birthdate: false,
				menu_govid_exp_date: false,
				rules: {
					required: value => !!value || 'E informashon aki ta rekeri!.',
					email: value => {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || 'Adrès elektróniko  aki ta no ta korekto.'
					},
					governmentid: value => (value && value.length == 10) ||
						'Number di sedula ta rekeri 10 sifra.',
					telephone_number: value => (value && value.length == 7) ||
						'Number di telefon por kontene solamente 7 sifra.',
					number_only: value => {
						const pattern = /^[0-9]+$/
						return pattern.test(value) || 'Solamente sifra ta permiti.'
					}
				},
				form: {
					id: 0,
					role_id: 1,
					schoolyear_id: 16,
					registration_type_id:1,
					sector_id: null,
					old_student: null,
					level_id: null,
					study_id: null,
					firstname: "",
					surname: "",
					maidenname: "",
					government_id: "",
					govid_exp_date: "",
					birthdate: "",
					birthplace_id: null,
					gender_id: null,
					nationality_id: null,
					maritial_status_id: null,
					denomination_id: null,
					address: "",
					neighboorhood:"",
					email: "",
					tel_home: "",
					tel_mobile: "",
					tel_work: "",
					registration_agree: "",
					registration_complete: "",
					registration_cost: null,
					study_cost: null,
					material_cost: "",
					display_name: "",
					edu_fo: 0,
					edu_fo_level: null,
					edu_fo_year: null,
					edu_vo: 0,
					edu_vo_level: null,
					edu_vo_year: null,
					edu_vsbo: 0,
					edu_vsbo_level: null,
					edu_vsbo_year: null,
					edu_sbo: 0,
					edu_sbo_level: null,
					edu_sbo_year: null,
					edu_other: 0,
					edu_other_level: null,
					edu_other_year: null,
					emergency_contact_1: null,
					emergency_phone_1: null,
					emergency_contact_2: null,
					emergency_phone_2: null
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			//this.getStudys();
			this.getGenders();
			this.getBirthplaces();
			this.getNationalitys();
			this.getMaritialstatus();
			this.getDenomination();
			this.getSector();
			if (this.id > 0) {
				this.getUserRegistration(this.id);
			}
		},
		watch: {
			name() {
				this.errorMessages = '';
			},
			"form.sector_id": function(value){
				
				if (this.cursector != this.form.sector_id && this.cursector > 0){
					this.form.level_id = null;
					this.form.registration_cost = null;
					this.form.study_cost = null;
					this.form.study_id = null;
					
				}

				this.getLevel(value);
				this.cursector = this.form.sector_id
				// this.getClass1(value);	
			},
			"form.level_id": function(value){
				
				if (this.curlevel != this.form.level_id && this.curlevel > 0){

				this.form.registration_cost = null;
				this.form.study_cost = null;
				this.form.study_id = null;
			}console.log('first',this.curlevel);
				this.getStudy(value);
				this.curlevel = this.form.level_id
				console.log('After',this.curlevel);
				// this.getClass1(value);	
			},
			"form.study_id": function(value){
				
				if (this.curstudy != this.form.study_id && this.curstudy > 0){

				this.form.registration_cost = null;
				this.form.study_cost = null;
				this.getSelectedStudy(value);
				}
				this.curstudy = this.form.study_id
				this.getSelectedStudy(value);
				// this.getClass1(value);
				
			},
			// "form.study_id": function (value) {
			// 	console.log("Get selected cost");
			// 	//console.log(this.cost_center_id_obj);
			// 	console.log(value);

			// 	this.getSelectedStudy(value);

			// },
		},
		methods: {
			validate() {
				this.$refs.form.validate()
			},
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getBirthplaces: function () {
				var self = this;

				ax.get("/birthplace/all").then(function (response) {
					console.log(response.data);
					self.birthplaces = response.data;
				});
			},
			getNationalitys: function () {
				var self = this;

				ax.get("/nationality/all").then(function (response) {
					console.log(response.data);
					self.nationalitys = response.data;
				});
			},
			getMaritialstatus: function () {
				var self = this;

				ax.get("/maritial_status/all").then(function (response) {
					console.log(response.data);
					self.maritial_statuss = response.data;
				});
			},
			getDenomination: function () {
				var self = this;

				ax.get("/denomination/all").then(function (response) {
					console.log(response.data);
					self.denominations = response.data;
				});
			},
			getSector: function () {
				var self = this;

				ax.get("/sector/all").then(function (response) {
					console.log(response.data);
					
					
					self.sectors = response.data;
				});
			},
			getLevel: function() {
			var self = this;
			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				
				self.levels = response.data;
				
				console.log("After Fill")
				
			});
			
		
		},
			getStudy: function () {
				var self = this;

				ax.get("/study/selected_sector_level/", {
				params: {
					sector_id: self.form.sector_id,
					level_id: self.form.level_id,
				}
			}).then(function(response) {
				// self.form.registration_cost = null;
				// self.form.study_cost = null;
				self.studys = response.data;
				
				console.log("After Fill")
				
			});
			},
			getSelectedStudy: function () {
				var self = this;
				ax.get("/study/single/" + this.form.study_id).then(function (response) {
					console.log("getStudy Costs")
					self.selectedstudycost = response.data;
					self.form.registration_cost = response.data.registration_cost;
					self.form.study_cost = response.data.study_cost;
					console.log(response.data);
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getGenders: function () {
				var self = this;

				ax.get("/gender/all").then(function (response) {
					console.log(response.data);
					self.genders = response.data;
				});
			},
			getUserRegistration: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/userregistration/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("UserRegistrations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;

			
				if (this.form.registration_agree == null || this.form.registration_agree == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_agree = null;
				}
				if (this.form.registration_complete == null || this.form.registration_complete == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_complete = null;
				}
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.maidenname.length > 0) {
					this.form.display_name = this.form.firstname + " " + this.form.surname + " e/v " + this.form
						.maidenname;
				} else {
					this.form.display_name = this.form.firstname + " " + this.form.surname;
				}
				if (this.formHasErrors == false) {
					if (this.form.id > 0) {
						ax.post("/userregistration/update/" + this.form.id, this.form).then(function (
							response
						) {
							console.log(response.data);

							console.log("UserRegistrations update...");

							self.$router.push({
								path: "/schooladmin/studentregistration"
							});
						});
					} else {
						console.log("before post");
						ax.post("/userregistration/create", this.form).then(function (response) {
							console.log("almost there");
							console.log(response.data);
							self.$router.push({
								path: "/bedankt"
							});
							console.log("UserRegistrations created...");
						});


					}
				} else {
					this.form
				}
				//});
			},
			submitFormTransition() {
				var self = this;
				this.formHasErrors = false;
				
				if (this.form.surname == null || this.form.surname == "") {
					this.formHasErrors = true;
					this.form.surname = null;
				}
				if (this.form.firstname == null || this.form.firstname == "") {
					this.formHasErrors = true;
					this.form.firstname = null;
				}
				if (this.form.birthdate == null || this.form.birthdate == "") {
					this.formHasErrors = true;
					this.form.birthdate = null;
				}
				if (this.form.govid_exp_date == null || this.form.govid_exp_date == "") {
					this.formHasErrors = true;
					this.form.govid_exp_date = null;
				}
				if (this.form.birthplace_id == null || this.form.birthplace_id == "") {
					this.formHasErrors = true;
					this.form.birthplace_id = "";
				}
				if (this.form.government_id == null || this.form.government_id == "" || this.form.government_id.length >10  ) {
					this.formHasErrors = true;
					this.form.government_id = null;
				}
				if (this.form.tel_mobile == null || this.form.tel_mobile == ""|| this.form.tel_mobile.length >7) {
					this.formHasErrors = true;
					this.form.tel_mobile = null;
				}
				if (this.form.email == null || this.form.email == "") {
					this.formHasErrors = true;
					this.form.email = null;
				}
				if (this.form.address == null || this.form.address == "") {
					this.formHasErrors = true;
					this.form.address = null;
				}
				if (this.form.neighboorhood == null || this.form.neighboorhood == "") {
					this.formHasErrors = true;
					this.form.neighboorhood = null;
				}
				if (this.form.gender_id == null || this.form.gender_id == "") {
					this.formHasErrors = true;
					this.form.gender_id = "";
				}
				if (this.form.denomination_id == null || this.form.denomination_id == "") {
					this.formHasErrors = true;
					this.form.denomination_id = "";
				}
				if (this.form.nationality_id == null || this.form.nationality_id == "") {
					this.formHasErrors = true;
					this.form.nationality_id = "";
				}
				if (this.form.maritial_status_id == null || this.form.maritial_status_id == "") {
					this.formHasErrors = true;
					this.form.maritial_status_id = "";
				}
				
				// if (this.form.study_id == null || this.form.study_id == "") {
				// 	this.formHasErrors = true;
				// 	this.form.study_id = "";
				// }
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			},
			submitFormTransitionStudie(){
				var self = this;
				this.formHasErrors = false;

					if (this.form.study_id == null || this.form.study_id == "") {
					this.formHasErrors = true;
					this.form.study_id = "";
				}
				if (this.form.old_student == null || this.form.old_student == "") {
					this.formHasErrors = true;
					this.form.old_student = "";
				}
					if (this.form.sector_id == null || this.form.sector_id == "") {
					this.formHasErrors = true;
					this.form.sector_id = "";
				}
					if (this.form.level_id == null || this.form.level_id == "") {
					this.formHasErrors = true;
					this.form.level_id = "";
				}
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			},
			submitFormTransitionEmergencyContact(){
				var self = this;
				this.formHasErrors = false;

					if (this.form.emergency_contact_1 == null || this.form.emergency_contact_1 == "") {
					this.formHasErrors = true;
					this.form.emergency_contact_1 = "";
				}
				if (this.form.emergency_phone_1 == null || this.form.emergency_phone_1 == "") {
					this.formHasErrors = true;
					this.form.emergency_phone_1 = "";
				}
				
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			}
		}
	};
</script>